export const TextTooltip ={
  ACESSLESS: 'Não Autorizado',
  ADD: 'Adicionar',
  ADD_MATRICULATION: 'Adicionar próxima Matrícula',
  ADD_PENDENCY: 'Adicionar próxima Pendência',
  ATTACHMENT: 'Anexos',
  APPROVALS: 'Aprovadores',

  CANCEL: 'Cancelar',
  CHANGE_ORIENTATION: 'Alter. Orientação',
  COMPLEMENTARY_DATA: 'Dados Complementares',
  COPY: 'Copia',

  DOWNLOAD: 'Download',
  DETAILS: 'Detalhes',

  EDIT: 'Editar',
  EDIT_PHOTO: 'Clique para selecionar Foto de Perfil',
  EXPORT: 'Exportar',

  HELP: 'Caso precise de ajuda, clique aqui!',
  HISTORIC: 'Histórico',

  INTERNAL_SERVICE: 'Atendimento Interno',
  ITEMS: 'Itens',
  MAP: 'Mapa',
  MAINTENANCE_FLEET: 'Manutenção de Frota',
  ITEMS_RETRACT: 'Ocultar Itens',
  ITEMS_SHOW: 'Exibir Itens',

  LESS_NF: 'Sem NF\'s',
  LESS_ATTACHMENT: 'Sem Anexos',
  LESS_QUOTATION: 'Sem Cotação',
  LIMIT: 'Limite da página',

  MONTHLY_ORDER: 'Pedido Mensal',
  NEXT: 'Próximo',
  NF_S: 'NF\'s',
  NO_OBSERVATION: 'Sem Observações',
  NO_PC: 'Somente para PC',
  NORMAL_PROCESS: 'Processo Normal',

  OBSERVATION: 'Observação',
  ORIENTATION: 'Orientação',

  PREVIOUS: 'Anterior',
  PRINTER: 'Imprimir',
  PRINTER_PC: 'Imprimir PC',

  QUOTATION: 'Cotação',

  REGULARIZATION: 'Regularização',
  REMOVE: 'Remover',

  SAVE: 'Salvar',
  SAVE_PHOTO: 'Clique para Salvar',
  SME_NEW: 'Nova SME',
  SME_COPY: 'Copia',
  STOCK: 'Consulta Estoque',

  URGENT_BUY: 'Compra Urgente',

  WRITE_DOWN: 'Abrir Anotações',

};
