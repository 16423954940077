import * as Yup from 'yup';


const SmeCopiaModalValidationSchema = Yup.object().shape({

  input_filial: Yup.string()
    .required('Campo Obrigatório'),

  input_armazem: Yup.string()
    .required('Campo Obrigatório'),

  input_num: Yup.string()
    .required('Campo Obrigatório'),

  input_utilizacao: Yup.string()
    .required('Campo obrigatório'),

  input_orientacao: Yup.string()
    .required('Campo obrigatório'),

  CP_TPMANUT: Yup.string()
    .when('input_orientacao', {
      is: '15',
      then: Yup.string()
        .required('Campo obrigatório'),
    }),


  CP_CLVL: Yup.string()
    .when('input_orientacao', {
      is: '15',
      then: Yup.string()
        .required('Campo obrigatório'),
    }),

  CP_MOTM: Yup.string()
    .when('CP_TPMANUT', {
      is: '01',
      then: Yup.string()
        .required('Campo obrigatório'),
    }),


});

export default SmeCopiaModalValidationSchema;
