import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Col,
  OverlayTrigger,
  Row,
} from 'react-bootstrap';

import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import {
  useSelector,
} from 'react-redux';

import {
  Formik,
} from 'formik';

import {
  AllItems,
} from '../../../../../common/arrays';

import {
  Colors,
  Images,
} from '../../../../../common/constants';

import { TextTooltip } from '../../../../../common/constants/TextTooltips';
import ExcelConfigs from '../../../../../common/excel';


import {
  ContainerView,
  Content,
  ContentStyled,
  FilterContainerForm,
  Screen,
  SectionMargin,
  TableAccordionStyled,
  TableDefaultStyled,
  TableToltip,
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../components/Empty';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';
import {
  IconAttachment,
  IconColapseExpand,
  IconHistory,
  IconInfoSquare,
  IconPrinter,
  IconColapseRetract,
  IconUserCheck,
  IconApproval,
} from '../../../../../components/Icons';
import LoadingsComponent from '../../../../../components/Loadings';
import DropdownInput from '../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../components/TextFields/Input';
import NameRoutes from '../../../../../navigation/name';
import {
  SME_NavRoutes,
} from '../../../../../navigation/navs/Supplies';
import ApiWS, { defaultBaseURL } from '../../../../../services/api.service';
import {
  RootState,
} from '../../../../../store';
import {
  returnErrorObject,
} from '../../../../../utils/ErrorUtils';
import SmeAnexosModal from '../Modals/AnexoSmeModal';
import SMApproversModal from '../Modals/ApproversModal';
import SmeHistoricoModal from '../Modals/HistoricoSmeModal';
import SmeAtendimentoModel from './SME-atendimento.model';
import {
  RowButtonFilterView,
} from './SME-atendimento.styled';
import SmeAtendimentoValidationSchema from './SME-atendimento.validation';



import './styled.css';
// eslint-disable-next-line import-helpers/order-imports
import BootstrapTable from 'react-bootstrap-table-next';
// eslint-disable-next-line import-helpers/order-imports
import { accentRemove } from '../../../../../utils/StringUtils';
import HistoricoAprovacaoSMEModal from '../Modals/HistoricoAprovacaoSMEModal';
import { IToast } from '../../../../../components/IToast/Toast';



const SME_AtendimentoScreen: React.FC = () => {
  const pasta = localStorage.getItem('@intranet/pasta');

  const user = useSelector((state: RootState) => state.user.data);


  const [carregando, setCarregando] = useState(true);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem SME\'s aqui!',
    description: 'Não há nenhuma SME para você, faça uma nova busca',
  });

  const [listOs, setListOs] = useState([]);
  const [listTriagem, setListTriagem] = useState([]);

  const [showListSM, setShowListSM] = useState(false);
  const [listaCarregando, setListaCarregando] = useState(false);
  const [listSMs, setListSMs] = useState([]);

  const [telaAnexo, setTelaAnexo] = useState(false);
  const [valueAnexo, setValueAnexo] = useState(null);

  const [modalHistorico, setModalHistorico] = useState(false);
  const [valueHistorico, setValueHistorico] = useState(null);

  const [showHistoricoAprovacaoSME, setShowHistoricoAprovacaoSME] = useState(false);
  const [valuesHistoricoAprovacaoSME, setValuesHistoricoAprovacaoSME] = useState(null);


  const [listItens, setListItens] = useState([]);

  const [selectSituacao, setSelectSituacao] = useState('');


  async function handleConsulta(values: any) {
    const objConsult = {
      OS: values.select_os.replaceAll(' ', ''),
      SITUAC: values.select_situacao.replaceAll(' ', ''),
      GRUPOTRIAG: values.select_grp_triagem.replaceAll(' ', ''),
      GRUPO: values.select_grp.replaceAll(' ', ''),
      OP: values.input_op,
      DTINI: values.input_de.replaceAll('-', ''),
      DTFIM: values.input_ate.replaceAll('-', ''),
      NUM: values.input_num_sm,
      DESCRI: values.input_descricao.toLocaleUpperCase(),
      EMITENTE: values.input_emitente.toLocaleUpperCase(),
      USER: user.id,
    };

    setListaCarregando(true);
    setShowListSM(false);

    values.select_situacao === '' || values.select_situacao === null
      ? setSelectSituacao('PENDENTE')
      : setSelectSituacao(selectSituacao);


    setListaCarregando(true);
    ApiWS()
      .post('/sm/atendimento/consulta/sm', objConsult)
      .then((resp) => {
        setListItens(resp.data);
        setListSMs(agrupaItensEmSM(resp.data));

        setShowListSM(true);
        setListaCarregando(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setListaCarregando(false);
        setShowListSM(true);
      });
  };


  const agrupaItensEmSM = (listItens) => {
    const newArr = [];
    let currentItem = { CP_NUM: '', CP_FILIAL: '' };
    listItens.forEach((item) => {
      if ((item.CP_NUM !== currentItem.CP_NUM)
        || (item.CP_NUM === currentItem.CP_NUM && item.CP_FILIAL !== currentItem.CP_FILIAL)) {
        newArr.push({ ...item, mostraItens: false });
        currentItem = item;
      }
    });
    return newArr.sort();
  };


  const filtraItens = (filial, numSM) => {
    const newArr = [];

    listItens.forEach((item) => {
      if (item.CP_NUM === numSM
        && item.CP_FILIAL === filial) {
        newArr.push(item);
      }
    });
    return newArr;
  };


  const handleShowItens = (filialNumSM) => {
    const listItens = document.querySelector(`#div-itens-${filialNumSM}`);
    // const btn = document.querySelector(`#btn-${numSM}`);

    if (listItens.classList.value === 'oculto') {
      listItens.classList.add('visivel');
      listItens.classList.remove('oculto');
    }
    else {
      listItens.classList.add('oculto');
      listItens.classList.remove('visivel');
    };

    // btn.classList.value === __CONSTANTS.IMG_SHOWITENS
    //   ? btn.classList = __CONSTANTS.IMG_HIDEITENS
    //   : btn.classList = __CONSTANTS.IMG_SHOWITENS;
  };


  function renderListOsInfo(listOsResp: any) {
    const listOsMap = listOsResp.map((os) => {
      const valueOs = os.OS !== '' || os.OS !== null || os.OS !== undefined ? os.OS : null;

      const OsMapped = {
        'key': os.OS,
        'label': valueOs,
        'value': valueOs,
      };
      return OsMapped;
    });

    setListOs([
      AllItems,
      ...listOsMap,
    ]);
  };


  async function findOrderService() {
    try {
      setCarregando(true);

      const url = '/sm/atendimento/consulta/os/';
      const response = await ApiWS().get(url);

      renderListOsInfo(response.data);
    }
    catch (error) {
      console.error('findOrderService', JSON.stringify(error.response, null, 2));
      if (error.response) {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setCarregando(false);
    }
  };


  function renderListGrpTriagem(listGrpResp: any) {
    const listGrpTriagemMap = listGrpResp.map((triagem) => {
      const valueTriagem = triagem.ZU_GRTRIAG !== '' || triagem.ZU_GRTRIAG !== null || triagem.ZU_GRTRIAG !== undefined ? triagem.ZU_GRTRIAG : null;

      const triagemMapped = {
        'key': triagem.ZU_GRTRIAG,
        'label': triagem.ZU_DESGRTR,
        'value': valueTriagem,
      };
      return triagemMapped;
    });

    setListTriagem([
      AllItems,
      ...listGrpTriagemMap,
    ]);
  };


  async function findTriagem() {
    try {
      setCarregando(true);

      const url = '/sm/atendimento/consulta/grupotriagem';
      const response = await ApiWS().get(url);

      renderListGrpTriagem(response.data);
    }
    catch (error) {
      console.error('findOrderService', JSON.stringify(error.response, null, 2));
      if (error.response) {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setCarregando(false);
    }
  };


  function handleVisaoItens(numSM: number, filial: number) {
    const newArray = listSMs.map((sm) => {
      return (
        (sm.CP_NUM === numSM && sm.CP_FILIAL === filial)
          ? { ...sm, mostraItens: !sm.mostraItens }
          : sm
      );
    });

    setListSMs(newArray);
  };


  function postExport(values: any) {
    setCarregando(true);

    const url = 'sme/atendimento/exporta';

    const payload = {
      OS: values.select_os.replaceAll(' ', ''),
      SITUAC: values.select_situacao.replaceAll(' ', ''),
      GRUPOTRIAG: values.select_grp_triagem.replaceAll(' ', ''),
      GRUPO: values.select_grp.replaceAll(' ', ''),
      OP: values.input_op,
      DTINI: values.input_de.replaceAll('-', ''),
      DTFIM: values.input_ate.replaceAll('-', ''),
      NUM: values.input_num_sm,
      DESCRI: values.input_descricao.toLocaleUpperCase(),
      EMITENTE: values.input_emitente.toLocaleUpperCase(),
      USER: user.id,
    };

    ApiWS()
      .post(url, [payload])
      .then((res) => {
        if (res.status === 200) {
          window.open(`${defaultBaseURL}/${pasta}/temp/${res.data}`);
        }
      })
      .catch((err) => {
        console.error('postFetchCard:::', JSON.stringify(err));

        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setCarregando(false);
        setShowListSM(true);
      });
  };


  function postPrinter(values: any) {
    setListaCarregando(true);

    const url = `/sme/atendimento/impressao/${values.CP_FILIAL}/${values.CP_NUM}`;

    ApiWS()
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          window.open(`${defaultBaseURL}/${pasta}/temp/${res.data}`);
        }
      })
      .catch((err) => {
        console.error('postPrinter:::', JSON.stringify(err));

        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setListaCarregando(false);
      });
  };


  useEffect(() => {
    findOrderService();
    findTriagem();
  }, []);


  const columns = [
    {
      dataField: 'CP_FILIAL',
      text: 'Filial',
      csvFormatter: (cell) => {
        return `'${cell}`;
      },
    },
    {
      dataField: 'CP_CCSOL',
      text: 'CC',
      csvFormatter: (cell) => {
        return `'${cell}`;
      },
      formatter: (cell, row) => {
        return (
          <OverlayTrigger
            key={'right'}
            placement={'right'}
            trigger={['hover', 'hover']}
            overlay={
              <TableToltip>
                {row.CCDESCR}
              </TableToltip>
            }>
            <span>{row.CP_CCSOL}</span>
          </OverlayTrigger>
        );
      },
    },
    {
      dataField: 'CP_NUM',
      text: 'SME',
      csvFormatter: (cell) => {
        return `'${cell}`;
      },
    },
    {
      dataField: 'CP_EMISSAO',
      text: 'Emissao',
    },
    {
      dataField: 'CP_SOLICIT',
      text: 'Solicitante',
      csvFormatter: (cell) => {
        return accentRemove(cell);
      },
    },
    {
      dataField: 'X5_DESCRI',
      text: 'Utilizacao',
      csvFormatter: (cell) => {
        return accentRemove(cell);
      },
    },
    {
      dataField: 'CP_ORIGPC',
      text: 'Orientacao',
      csvFormatter: (cell) => {
        return accentRemove(cell);
      },
    },
    // ---- ITENS -----------------
    {
      dataField: 'CP_ITEM',
      text: 'Item',
    },
    {
      dataField: 'CP_DESCRI',
      text: 'Descricao',
      csvFormatter: (cell) => {
        return accentRemove(cell);
      },
    },
    {
      dataField: 'CP_DESCOMP',
      text: 'Desc. Compl.',
      csvFormatter: (cell) => {
        return accentRemove(cell);
      },
    },
    {
      dataField: 'CP_QTSOLIC',
      text: 'Qth. Solict.',
    },
    {
      dataField: 'CP_QUANT',
      text: 'Qth. Aprov.',
    },
    {
      dataField: 'CP_PENDENTE',
      text: 'Qth. Pende.',
    },
    {
      dataField: 'CP_DTPENTR',
      text: 'Data Prev. Entrega',
    },
    {
      dataField: 'CP_DATPRF',
      text: 'Data Necessidade',
    },
    {
      dataField: 'CP_GRUPOTRIAGEM',
      text: 'Grp. Origem',
      csvFormatter: (cell) => {
        return accentRemove(cell);
      },
    },
  ];



  return (
    <ToolkitProvider
      keyField={'id'}
      data={listItens}
      columns={columns}
      exportCSV={{
        blobType: ExcelConfigs.blobType,
        fileName: ExcelConfigs.SME_Atendimento,
        onlyExportFiltered: ExcelConfigs.onlyExportFiltered,
        exportAll: ExcelConfigs.exportAll,
        noAutoBOM: false,
        separator: ';',
      }}>
      {(propsTkProvider) => (
        <Screen>

          <NavHeader>
            <NavUser backHome />


            <NavComp
              navKey={5}
              navArray={SME_NavRoutes}
              title={'SME'}
            />
          </NavHeader>



          <Content>
            <ContentStyled fluid>

              <ContainerView>

                <SectionMargin>
                  <TitleGroup>
                    {'Atendimento SME'.toUpperCase()}
                  </TitleGroup>


                  {carregando && (
                    <LoadingsComponent
                      type={'SCREEN'}
                      color={Colors?.tertiary}
                    />
                  )}


                  {listaCarregando && (
                    <LoadingsComponent
                      type={'SCREEN'}
                      color={Colors?.tertiary}
                    />
                  )}


                  <SectionMargin>
                    <Formik
                      enableReinitialize
                      validateOnMount
                      validationSchema={SmeAtendimentoValidationSchema}
                      initialValues={SmeAtendimentoModel}
                      onSubmit={(values) => {
                        handleConsulta(values);
                      }}>
                      {({
                        values,
                        resetForm,
                        isValid,
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        setValues,
                      }) => (

                        <FilterContainerForm auto onSubmit={handleSubmit}>

                          <Row>
                            <Col>
                              <DropdownInput
                                name={'select_os'}
                                label={'CC'}
                                defaultValue={'Todos'.toLocaleUpperCase()}
                                dropArray={listOs}
                                value={values.select_os || 'Todos'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />


                              <DropdownInput
                                name={'select_situacao'}
                                label={'Situação'}
                                defaultValue={'Pendente'.toLocaleUpperCase()}
                                dropArray={[
                                  {
                                    key: '1',
                                    label: 'Pendentes',
                                    value: 'Pendente'.toLocaleUpperCase(),
                                  },
                                  {
                                    key: '2',
                                    label: 'Encerradas',
                                    value: 'Encerradas'.toLocaleUpperCase(),
                                  },
                                  {
                                    key: '3',
                                    label: 'Em Aprovação',
                                    value: 'Em Aprovação'.toLocaleUpperCase(),
                                  },
                                  {
                                    key: '4',
                                    label: 'Inativa',
                                    value: 'Inativa'.toLocaleUpperCase(),
                                  },
                                ]}
                                value={values.select_situacao || 'Pendente'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />


                              <DropdownInput
                                name={'select_grp_triagem'}
                                label={'Grupo Triagem'}
                                defaultValue={'Todos'.toLocaleUpperCase()}
                                dropArray={listTriagem}
                                value={values.select_grp_triagem || 'Todos'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />


                              <DropdownInput
                                name={'select_grp'}
                                label={'Grupo'}
                                defaultValue={'Todos'.toLocaleUpperCase()}
                                dropArray={[
                                  {
                                    key: 'All',
                                    label: 'Todos',
                                    value: 'Todos'.toLocaleUpperCase(),
                                  },
                                  {
                                    key: '1',
                                    label: 'Serviço',
                                    value: 'Serviço'.toLocaleUpperCase(),
                                  },
                                  {
                                    key: '2',
                                    label: 'Consumo/Mercadorias',
                                    value: 'Consumo/Mercadorias'.toLocaleUpperCase(),
                                  },
                                  {
                                    key: '3',
                                    label: 'Mat. de Aplicação',
                                    value: 'Mat. de Aplicação'.toLocaleUpperCase(),
                                  },
                                  {
                                    key: '4',
                                    label: 'Bens Duráveis',
                                    value: 'Bens Duráveis'.toLocaleUpperCase(),
                                  },
                                ]}
                                value={values.select_grp || 'Todos'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Col>



                            <Col>
                              <TextInput
                                type={'text'}
                                name={'input_op'}
                                label={'OP'}
                                placeholder={'OP'}
                                min={0}
                                value={values.input_op || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />


                              <TextInput
                                type={'date'}
                                name={'input_de'}
                                label={'De'}
                                placeholder={'De'}
                                value={values.input_de || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />


                              <TextInput
                                type={'date'}
                                name={'input_ate'}
                                label={'Até'}
                                placeholder={'Até'}
                                value={values.input_ate || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Col>



                            <Col>
                              <TextInput
                                type={'text'}
                                name={'input_num_sm'}
                                label={'Número SME'}
                                placeholder={'Número'}
                                max={6}
                                value={values.input_num_sm || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />


                              <TextInput
                                type={'text'}
                                name={'input_descricao'}
                                label={'Decrição'}
                                placeholder={'Decrição'}
                                value={values.input_descricao || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />


                              <TextInput
                                type={'text'}
                                name={'input_emitente'}
                                label={'Solicitante'}
                                placeholder={'Solicitante'}
                                value={values.input_emitente || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Col>
                          </Row>



                          <RowButtonFilterView>

                            <IButton
                              disabled={listItens.length <= 0 || carregando || listaCarregando}
                              type={'button'}
                              text={'Exportar'}
                              backgroundColor={Colors?.excel}
                              onClick={() => {
                                postExport(values);
                              }}
                            />



                            <IButton
                              text={'Limpar'}
                              backgroundColor={Colors?.gray}
                              type={'reset'}
                              onClick={() => {
                                resetForm();

                                setValues(SmeAtendimentoModel);
                                setListItens([]);
                              }}
                            />


                            <IButton
                              disabled={!isValid || carregando || listaCarregando}
                              text={'Filtrar'}
                              backgroundColor={Colors?.primary}
                              type={'submit'}
                            />
                          </RowButtonFilterView>

                        </FilterContainerForm>

                      )}
                    </Formik>
                  </SectionMargin>



                  <ContainerView>

                    {!carregando && !listaCarregando && showListSM && listSMs.length === 0 && (
                      <EmptyContent
                        image={emptyObject.image}
                        title={emptyObject.title}
                        description={emptyObject.description}
                      />
                    )}



                    {!carregando && !listaCarregando && showListSM && listSMs.length > 0 && listItens.length > 0 && (
                      <TableAccordionStyled>
                        <thead>
                          <tr>
                            <th>Itens</th>
                            <th>Filial</th>
                            <th>CC</th>
                            <th>SME</th>
                            <th>Data</th>
                            <th>Solicitante</th>
                            <th>Utilização</th>
                            <th>Orientação</th>
                            <th colSpan={4}>Ações</th>
                          </tr>
                        </thead>


                        <tbody>
                          {listSMs.map((sm, index) => (
                            <>
                              <tr key={index}>
                                <td className="actions" onClick={() => {
                                  handleShowItens(`${sm.CP_FILIAL}${sm.CP_NUM}`);
                                  handleVisaoItens(sm.CP_NUM, sm.CP_FILIAL);
                                }}>
                                  <ButtonTableAction
                                    key={'right'}
                                    placement={'right'}
                                    tooltip={
                                      sm.mostraItens ? TextTooltip.ITEMS_RETRACT : TextTooltip.ITEMS_SHOW
                                    }
                                    icon={
                                      sm.mostraItens
                                        ? <IconColapseExpand color={Colors?.black} size={16} />
                                        : <IconColapseRetract color={Colors?.black} size={16} />
                                    }
                                  />
                                </td>

                                <td>{sm.CP_FILIAL}</td>
                                <td>{sm.CP_CCSOL}</td>
                                <td>{sm.CP_NUM}</td>
                                <td>{sm.CP_EMISSAO}</td>
                                <td>{sm.CP_SOLICIT}</td>
                                <td>{sm.X5_DESCRI.replaceAll(' ', '')}</td>
                                <td>{sm.CP_ORIGPC}</td>


                                {/* Botão Observacao */}
                                <td className={'actions'}>
                                  <ButtonTableAction
                                    tooltip={
                                      sm.CP_OBSERVA || sm.CP_OBSERVA !== '' || sm.CP_OBSERVA !== null
                                        ? sm.CP_OBSERVA
                                        : 'Sem Observações'
                                    }
                                    icon={
                                      <IconInfoSquare color={Colors?.black} size={16} />
                                    }
                                  />
                                </td>

                                {/* Botão Impressao */}
                                <td className="actions">
                                  <ButtonTableAction
                                    tooltip={TextTooltip.PRINTER}
                                    onClick={() => {
                                      postPrinter(sm);
                                    }}
                                    icon={
                                      <IconPrinter
                                        color={Colors?.black}
                                        size={16}
                                      />
                                    }
                                  />
                                </td>


                                {/* Botão Modal Anexos */}
                                <td className="actions">
                                  {sm.ANEXO === 'S' ? (
                                    <ButtonTableAction
                                      tooltip={TextTooltip.ATTACHMENT}
                                      onClick={() => {
                                        setValueAnexo({
                                          FILIAL: sm.CP_FILIAL,
                                          NUM: sm.CP_NUM,
                                          showModal: false,
                                        });
                                        setTelaAnexo(true);
                                      }}
                                      icon={

                                        <IconAttachment
                                          color={Colors?.black}
                                          size={16}
                                        />
                                      }
                                    />
                                  ) : (
                                    <ButtonTableAction
                                      tooltip={TextTooltip.LESS_ATTACHMENT}
                                      icon={
                                        <IconAttachment
                                          color={Colors?.gray}
                                          size={16}
                                        />
                                      }
                                    />
                                  )}
                                </td>
                              </tr>



                              <tr className="no-style">
                                <td colSpan={12} className="no-style">
                                  <tr id={`div-itens-${sm.CP_FILIAL}${sm.CP_NUM}`} className="oculto">

                                    <TableDefaultStyled>
                                      <thead className="thead-two">
                                        <tr>
                                          <th>Item</th>
                                          <th>Descrição</th>
                                          <th>Desc. Compl.</th>
                                          <th>Qth. Solict.</th>
                                          <th>Qth. Aprov.</th>
                                          <th>Qth. Pende.</th>
                                          <th>Data Prev. Entrega</th>
                                          <th>Data Necessidade</th>
                                          <th>Grp. Origem</th>
                                          <th colSpan={2}>Histórico</th>
                                        </tr>
                                      </thead>



                                      <tbody className="tbody-two">
                                        {filtraItens(sm.CP_FILIAL, sm.CP_NUM).map((item) => (
                                          <tr>
                                            <td>{item.CP_ITEM}</td>
                                            <td>{item.CP_DESCRI}</td>
                                            <td>{item.CP_DESCOMP}</td>
                                            <td>{item.CP_QTSOLIC}</td>
                                            <td>{item.CP_QUANT}</td>
                                            <td>{item.CP_PENDENTE}</td>
                                            <td>{item.CP_DTPENTR}</td>
                                            <td>{item.CP_DATPRF}</td>
                                            <td>{item.CP_GRUPOTRIAGEM}</td>


                                            {/* Botão Modal Historico */}
                                            <td className="actions">
                                              <ButtonTableAction
                                                tooltip={`${TextTooltip.HISTORIC}: Item ${item.CP_ITEM} | SM ${item.CP_NUM}`}
                                                onClick={() => {
                                                  setValueHistorico(item);
                                                  setModalHistorico(true);
                                                }}
                                                icon={
                                                  <IconHistory
                                                    color={Colors?.black}
                                                    size={16}
                                                  />
                                                }
                                              />
                                            </td>


                                            {/* Botão Modal Aprovadores */}
                                            <td className="actions">
                                              <ButtonTableAction
                                                tooltip={TextTooltip.APPROVALS}
                                                onClick={() => {
                                                  setShowHistoricoAprovacaoSME(true);
                                                  setValuesHistoricoAprovacaoSME({
                                                    'FILIAL': sm.CP_FILIAL,
                                                    'CC': sm.CP_CCSOL,
                                                    'NUM': sm.CP_NUM,
                                                    'ITEM': sm.CP_ITEM,
                                                  });
                                                }}
                                                icon={
                                                  <IconApproval
                                                    color={Colors?.black}
                                                    size={16}
                                                  />
                                                }
                                              />
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </TableDefaultStyled>

                                  </tr>
                                </td>
                              </tr>

                            </>
                          ))}
                        </tbody>
                      </TableAccordionStyled>


                    )}
                    <div className={'d-none'}>
                      <BootstrapTable {...propsTkProvider.baseProps} />
                    </div>

                  </ContainerView>


                </SectionMargin>

              </ContainerView >

            </ContentStyled >
          </Content >



          {valueAnexo && (
            <SmeAnexosModal
              show={telaAnexo}
              anexar={false}
              sm={valueAnexo}
              onHide={() => {
                setTelaAnexo(false);
                setTimeout(() => {
                  setValueAnexo(null);
                }, 300);
              }}
            />
          )}


          {valueHistorico && (
            <SmeHistoricoModal
              show={modalHistorico}
              todo={valueHistorico}
              onHide={() => {
                setModalHistorico(false);
                setTimeout(() => {
                  setValueHistorico(null);
                }, 300);
              }}
            />
          )}


          {showHistoricoAprovacaoSME && valuesHistoricoAprovacaoSME && (
            <HistoricoAprovacaoSMEModal
              show={showHistoricoAprovacaoSME}
              onHide={() => setShowHistoricoAprovacaoSME(false)}
              value={valuesHistoricoAprovacaoSME}
            />
          )}

        </Screen >



      )}
    </ToolkitProvider >

  );
};



export default SME_AtendimentoScreen;
