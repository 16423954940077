const SmeModalCopiaModel = {
  input_filial: '',
  input_num: '',
  input_orientacao: '',
  input_utilizacao: '',
  CP_TPMANUT:  '',
  CP_CLVL:  '',
  CP_MOTM:  '',
};


export default SmeModalCopiaModel;
