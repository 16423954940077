import React,
{
  useState,
  useEffect,
} from 'react';

import {
  Modal,
} from 'react-bootstrap';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import {
  Colors,
} from '../../../../../common/constants';

import {
  TableActionButton,
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';
import NoItemsContent from '../../../../../components/Contents/NoItems';

import {
  IconDowload,
} from '../../../../../components/Icons';

import LoadingsComponent from '../../../../../components/Loadings';

import {
  ModalBodyStyled,
  ModalContainerStyled,
  ModalFooterStyled,
} from '../../../../../components/Modals/styled';

import TableCuston from '../../../../../components/Table';

import ApiWS, { defaultBaseURL } from '../../../../../services/api.service';



interface IProps {
  show: boolean;
  buttonText?: string;
  values?: any;
  onHide?: any;
}



const AnexosPcbModal: React.FC<IProps> = (props) => {
  const pasta = window.localStorage.getItem('@intranet/pasta');

  const [loading, setLoading] = useState(false);

  const [BackFiles, setBackFiles] = useState([]);


  function downloadAttached (fileName) {
    window.open(`${defaultBaseURL}/${pasta}/anexos/${fileName}`);
  };


  function renderBtnDownloadAtt (cell, row) {
    return (
      <TableActionButton
        onClick={() => {
          downloadAttached(row.nome);
        }}>
        <span>{row.sup_sm_originalNome}</span>

        <IconDowload
          color={Colors?.black}
          size={16}
        />
      </TableActionButton>
    );
  };


  async function getAttachedPCBM () {
    const pedidoBm = props.values;

    setLoading(true);

    ApiWS()
      .get(`/suprimento/anexo/envio_anexos/${pedidoBm.NUM}/${pedidoBm.FILIAL}/${pedidoBm.TIPO}`)
      .then((resp) => {
        console.log('RESP:::', resp.data);
        setBackFiles(resp.data);
      })
      .catch((err) => {
        // setErro(err);
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  async function postAttachedContract () {
    const payload = {
      'TIPO': props.values?.TIPO,
      'CONTRATO': props.values?.NUM,
      'FILIAL': props.values?.FILIAL,
    };


    setLoading(true);

    ApiWS()
      .post('/suprimento/anexo/envio_anexos/contrato', payload)
      .then((resp) => {
        setBackFiles(resp.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  function returnPropsTipo() {
    return props.values.TIPO;
  };


  function returnSizeFile(cell: any, _row: any) {
    return `${(parseFloat(cell) / 100000).toFixed(2)} MB`;
  };



  useEffect(() => {
    (props.values?.TIPO === 'PC' ||
    props.values?.TIPO === 'BM' ||
    props.values?.TIPO === 'AE' ||
    props.values?.TIPO === 'MD') &&
      getAttachedPCBM();

    (props.values?.TIPO === 'CT' ||
    props.values?.TIPO === 'CP') &&
      postAttachedContract();
  }, []);



  return (

    <Modal
      {...props}
      show={props.show}
      backdrop={'static'}
      size={'lg'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {'Anexos Suprimentos'.toUpperCase()}
        </TitleGroup>



        <ModalBodyStyled>

          {loading && (
            <LoadingsComponent
              type={'SCREEN'}
              color={Colors?.tertiary}
            />
          )}


          {!loading && (!BackFiles || BackFiles === null || BackFiles.length === 0) && (
            <NoItemsContent />
          )}


          {!loading && BackFiles && BackFiles.length > 0 && (
            <PaginationProvider
              pagination={paginationFactory({
                custom: true,
                totalSize: BackFiles.length,
              })}>
              {({
                paginationProps,
                paginationTableProps,
              }) => (
                <TableCuston
                  noQuantity
                  data={BackFiles}
                  columns={[
                    {
                      dataField: '',
                      text: 'Tipo',
                      sort: true,
                      headerStyle: () => {
                        return { width: '8%' };
                      },
                      formatter: () => {
                        return returnPropsTipo();
                      },
                    },
                    {
                      dataField: 'originalNome',
                      text: 'Nome do Arquivo',
                      sort: true,
                    },
                    {
                      dataField: 'tamanho',
                      text: 'Tamanho',
                      sort: true,
                      headerStyle: () => {
                        return { width: '10%' };
                      },
                      formatter: (cell, row) => {
                        return returnSizeFile(cell, row);
                      },
                    },
                    {
                      dataField: '',
                      text: 'Download',
                      headerStyle: () => {
                        return { width: '15%' };
                      },
                      formatter: (cell, row) => {
                        return renderBtnDownloadAtt(cell, row);
                      },
                    },
                  ]}
                  paginationProps={paginationProps}
                  paginationTableProps={paginationTableProps}
                />
              )}
            </PaginationProvider>
          )}

        </ModalBodyStyled>



        <ModalFooterStyled>
          <IButton
            backgroundColor={Colors?.primary}
            text={props.buttonText}
            onClick={props.onHide}
          />
        </ModalFooterStyled>

      </ModalContainerStyled>

    </Modal>

  );
};



AnexosPcbModal.defaultProps = {
  buttonText: 'Fechar',
};



export default AnexosPcbModal;
