import React from 'react';

import {
  Tooltip,
} from 'react-bootstrap';

import {
  AiFillEye,
  AiFillEyeInvisible,
  AiFillPrinter,
  AiOutlineSelect,
  AiOutlineCheck,
  AiOutlineRight,
  AiOutlineDown,
  AiFillSetting,
  AiFillHome,
  AiFillCloseSquare,
  AiTwotoneStar,
  AiOutlineFullscreen,
  AiOutlineFullscreenExit,
} from 'react-icons/ai';

import {
  BiCalendar,
  BiCopy,
  BiHappyBeaming,
  BiLogOut,
  BiTransfer,
  BiWrench,
} from 'react-icons/bi';

import {
  BsBank2,
  BsBootstrapReboot,
  BsCalculatorFill,
  BsCardChecklist,
  BsCashCoin,
  BsChevronDoubleDown,
  BsChevronDoubleLeft,
  BsChevronDoubleRight,
  BsClipboardCheck,
  BsCoin,
  BsCreditCard2BackFill,
  BsCurrencyExchange,
  BsDiagram3Fill,
  BsExclamationTriangleFill,
  BsFillInfoSquareFill,
  BsFillLayersFill,
  BsFillTrashFill,
  BsGraphUp,
  BsMessenger,
  BsPaperclip,
  BsPeopleFill,
  BsPersonBadge,
  BsPersonBadgeFill,
  BsPersonBoundingBox,
  BsPinMapFill,
  BsVectorPen,
  BsWindowSidebar,
} from 'react-icons/bs';

import {
  CgArrowsShrinkH,
  CgImport,
  CgInternal,
} from 'react-icons/cg';

import {
  FaClipboardList,
  FaCookieBite,
  FaDownload,
  FaFileContract,
  FaFolderPlus,
  FaHistory,
  FaMinusCircle,
  FaSearch,
  FaTruck,
  FaFlag,
  FaBox,
  FaBoxes,
  FaExternalLinkSquareAlt,
  FaHardHat,
  FaObjectGroup,
  FaPeopleArrows,
  FaPeopleCarry,
  FaUserClock,
  FaUserFriends,
  FaUsersCog,
} from 'react-icons/fa';

import {
  FiRefreshCcw,
} from 'react-icons/fi';

import {
  GiBrickWall,
  GiFactory,
} from 'react-icons/gi';

import {
  GrRadialSelected,
} from 'react-icons/gr';

import {
  HiDocumentDownload,
  HiOutlinePresentationChartLine,
  HiPaperClip,
} from 'react-icons/hi';

import {
  ImCart,
  ImFolderOpen,
  ImMail4,
  ImShield,
  ImUser,
  ImUserCheck,
  ImUserPlus,
  ImUsers,
} from 'react-icons/im';

import {
  IoIdCard,
  IoDocumentSharp,
  IoDocumentsSharp,
  IoDocumentAttachSharp,
  IoQrCodeSharp,
  IoSend,
  IoBusinessSharp,
  IoWarning,
} from 'react-icons/io5';

import {
  MdOutlineNavigateNext,
  MdAdd,
  MdAddBox,
  MdArrowBack,
  MdAttachMoney,
  MdDoNotDisturbAlt,
  MdFeaturedPlayList,
  MdListAlt,
  MdMore,
  MdOutlineArrowDropDown,
  MdOutlineArrowRight,
  MdOutlineLiveHelp,
  MdOutlineMonitor,
  MdWbTwilight,
  MdOutlineNavigateBefore,
  MdAddAPhoto,
  MdInsertComment,
  MdIncompleteCircle,
  MdCarCrash,
} from 'react-icons/md';

import {
  RiBarChartFill,
  RiCheckboxMultipleFill,
  RiEditBoxFill,
  RiEmotionSadLine,
  RiFileExcel2Line,
  RiFileTransferFill,
  RiFingerprintLine,
  RiFolderSettingsFill,
  RiLuggageDepositFill,
  RiOrganizationChart,
  RiPlayListAddFill,
} from 'react-icons/ri';

import {
  TbDialpad,
} from 'react-icons/tb';

import {
  TiCancel,
  TiSortNumerically,
} from 'react-icons/ti';

import {
  Colors,
  Metrics,
} from '../../common/constants';



interface IProps {
  id?: string;
  className?: string;

  size?: number;
  color?: string;

  style?: any;

  onClick?: any;
}



export const IconAdd: React.FC<IProps> = (props) => {
  return (
    <MdAdd
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention} />

  );
};



export const IconAddTag: React.FC<IProps> = (props) => {
  return (
    <MdAddBox
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention} />

  );
};



export const IconAdvances: React.FC<IProps> = (props) => {
  return (
    <BsCreditCard2BackFill
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention} />

  );
};



export const IconApproval: React.FC<IProps> = (props) => {
  return (
    <FaUserFriends
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention} />

  );
};



export const IconArchive: React.FC<IProps> = (props) => {
  return (

    <CgImport
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconArchiveImport: React.FC<IProps> = (props) => {
  return (

    <CgImport
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconArrowDown: React.FC<IProps> = (props) => {
  return (

    <MdOutlineArrowDropDown
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconArrowRight: React.FC<IProps> = (props) => {
  return (

    <MdOutlineArrowRight
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconArrowShrink: React.FC<IProps> = (props) => {
  return (

    <CgArrowsShrinkH
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconAttachment: React.FC<IProps> = (props) => {
  return (

    <HiPaperClip
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention} />

  );
};



export const IconBack: React.FC<IProps> = (props) => {
  return (

    <MdArrowBack
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};


export const IconCA: React.FC<IProps> = (props) => {
  return (

    <ImShield
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};


export const IconCalendar: React.FC<IProps> = (props) => {
  return (

    <BiCalendar
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconBranch: React.FC<IProps> = (props) => {
  return (

    <BsPinMapFill
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconCancel: React.FC<IProps> = (props) => {
  return (

    <TiCancel
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.buttonCancel}
    />

  );
};



export const IconCardId: React.FC<IProps> = (props) => {
  return (

    <IoIdCard
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconCart: React.FC<IProps> = (props) => {
  return (

    <ImCart
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconChartBar: React.FC<IProps> = (props) => {
  return (

    <RiBarChartFill
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconCheck: React.FC<IProps> = (props) => {
  return (

    <AiOutlineCheck
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};

export const IconNot: React.FC<IProps> = (props) => {
  return (

    <MdDoNotDisturbAlt
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconCheckCopy: React.FC<IProps> = (props) => {
  return (

    <RiCheckboxMultipleFill
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconCheckList: React.FC<IProps> = (props) => {
  return (

    <BsCardChecklist
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconClipboard: React.FC<IProps> = (props) => {
  return (

    <FaClipboardList
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention} />

  );
};


export const IconClose: React.FC<IProps> = (props) => {
  return (

    <AiFillCloseSquare
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
      onClick={props.onClick}
    />

  );
};



export const IconCollaboration: React.FC<IProps> = (props) => {
  return (

    <FaPeopleCarry
      id={props.id}
      className={props.className}
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconCostPFX: React.FC<IProps> = (props) => {
  return (

    <FaUsersCog
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconCompany: React.FC<IProps> = (props) => {
  return (

    <IoBusinessSharp
      id={props.id}
      className={props.className}
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconIncomplete: React.FC<IProps> = (props) => {
  return (

    <MdIncompleteCircle
      id={props.id}
      className={props.className}
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconColetor: React.FC<IProps> = (props) => {
  return (

    <TbDialpad
      id={props.id}
      className={props.className}
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconColapseExpand: React.FC<IProps> = (props) => {
  return (

    <AiOutlineDown
      id={props.id}
      className={props.className}
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};

export const IconColapseExpand2: React.FC<IProps> = (props) => {
  return (

    <BsChevronDoubleDown
      id={props.id}
      className={props.className}
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconColapseRetract: React.FC<IProps> = (props) => {
  return (

    <AiOutlineRight
      id={props.id}
      className={props.className}
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};

export const IconColapseRetract2: React.FC<IProps> = (props) => {
  return (

    <BsChevronDoubleRight
      id={props.id}
      className={props.className}
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconContract: React.FC<IProps> = (props) => {
  return (

    <FaFileContract
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconContract2: React.FC<IProps> = (props) => {
  return (

    <BsVectorPen
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconControl: React.FC<IProps> = (props) => {
  return (

    <BsClipboardCheck
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconCookie: React.FC<IProps> = (props) => {
  return (

    <FaCookieBite
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconCost: React.FC<IProps> = (props) => {
  return (

    <BsCoin
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconHardHappy: React.FC<IProps> = (props) => {
  return (

    <BiHappyBeaming
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconHardHat: React.FC<IProps> = (props) => {
  return (

    <FaHardHat
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};


export const IconNetwork: React.FC<IProps> = (props) => {
  return (
    <BsDiagram3Fill
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.white}
    />

  );
};


export const IconNext: React.FC<IProps> = (props) => {
  return (
    <MdOutlineNavigateNext
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.white}
    />

  );
};

export const IconNext2: React.FC<IProps> = (props) => {
  return (
    <BsChevronDoubleRight
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.white}
    />

  );
};

export const IconObjectGroup: React.FC<IProps> = (props) => {
  return (

    <FaObjectGroup
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconDevolution: React.FC<IProps> = (props) => {
  return (

    <RiFileTransferFill
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconDirectLabor: React.FC<IProps> = (props) => {
  return (

    <BsPersonBadgeFill
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconDocument: React.FC<IProps> = (props) => {
  return (

    <IoDocumentSharp
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention} />

  );
};



export const IconDocuments: React.FC<IProps> = (props) => {
  return (

    <IoDocumentsSharp
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconDowload: React.FC<IProps> = (props) => {
  return (

    <FaDownload
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention} />

  );
};



export const IconEdit: React.FC<IProps> = (props) => {
  return (

    <RiEditBoxFill
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention} />

  );
};



export const IconEditPhoto: React.FC<IProps> = (props) => {
  return (

    <MdAddAPhoto
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.black} />

  );
};



export const IconEffective: React.FC<IProps> = (props) => {
  return (

    <BsPeopleFill
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention} />

  );
};



export const IconEyeClose: React.FC<IProps> = (props) => {
  return (

    <AiFillEyeInvisible
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};


export const IconEyeOpen: React.FC<IProps> = (props) => {
  return (

    <AiFillEye
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};


export const IconExcel: React.FC<IProps> = (props) => {
  return (

    <RiFileExcel2Line
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconFactory: React.FC<IProps> = (props) => {
  return (

    <GiFactory
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconFingerprint: React.FC<IProps> = (props) => {
  return (

    <RiFingerprintLine
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconFiscal: React.FC<IProps> = (props) => {
  return (

    <HiDocumentDownload
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconFlag: React.FC<IProps> = (props) => {
  return (

    <FaFlag
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention} />

  );
};



export const IconFolder: React.FC<IProps> = (props) => {
  return (

    <ImFolderOpen
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention} />

  );
};



export const IconFolderPlus: React.FC<IProps> = (props) => {
  return (

    <FaFolderPlus
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconHelpOutline: React.FC<IProps> = (props) => {
  return (

    <MdOutlineLiveHelp
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconHistory: React.FC<IProps> = (props) => {
  return (

    <FaHistory
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention} />

  );
};



export const IconHome: React.FC<IProps> = (props) => {
  return (

    <AiFillHome
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconHour: React.FC<IProps> = (props) => {
  return (

    <FaUserClock
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconInConstruction: React.FC<IProps> = (props) => {
  return (

    <GiBrickWall
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.primary}
    />

  );
};



export const IconIndirectLabor: React.FC<IProps> = (props) => {
  return (

    <BsPersonBadge
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention} />

  );
};



export const IconInfoSquare: React.FC<IProps> = (props) => {
  return (

    <BsFillInfoSquareFill
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention} />

  );
};



export const IconInvoicing: React.FC<IProps> = (props) => {
  return (

    <BsCashCoin
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention} />

  );
};



export const IconItemExternal: React.FC<IProps> = (props) => {
  return (

    <FaExternalLinkSquareAlt
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconItemInternal: React.FC<IProps> = (props) => {
  return (

    <CgInternal
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconList: React.FC<IProps> = (props) => {
  return (

    <MdListAlt
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention} />

  );
};



export const IconListFeatured: React.FC<IProps> = (props) => {
  return (

    <MdFeaturedPlayList
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconListNew: React.FC<IProps> = (props) => {
  return (

    <RiPlayListAddFill
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention} />

  );
};



export const IconLogout: React.FC<IProps> = (props) => {
  return (

    <BiLogOut
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconMailWeb: React.FC<IProps> = (props) => {
  return (

    <ImMail4
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconMaintenanceAttached: React.FC<IProps> = (props) => {
  return (

    <RiFolderSettingsFill
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};

export const IconMaintenanceFleet: React.FC<IProps> = (props) => {
  return (

    <MdCarCrash
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconMesseger: React.FC<IProps> = (props) => {
  return (

    <BsMessenger
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconMoney: React.FC<IProps> = (props) => {
  return (

    <MdAttachMoney
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconMonitor: React.FC<IProps> = (props) => {
  return (

    <MdOutlineMonitor
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconQrCode: React.FC<IProps> = (props) => {
  return (

    <IoQrCodeSharp
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconMoreOptions: React.FC<IProps> = (props) => {
  return (

    <MdMore
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention} />

  );
};



export const IconOrganization: React.FC<IProps> = (props) => {
  return (

    <RiOrganizationChart
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconOutsourcedLabor: React.FC<IProps> = (props) => {
  return (

    <BsPersonBoundingBox
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconPeopleBetween: React.FC<IProps> = (props) => {
  return (

    <FaPeopleArrows
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconPresentation: React.FC<IProps> = (props) => {
  return (

    <HiOutlinePresentationChartLine
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconPrinter: React.FC<IProps> = (props) => {
  return (

    <AiFillPrinter
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention} />

  );
};



export const IconPaperclip: React.FC<IProps> = (props) => {
  return (

    <BsPaperclip
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconParams: React.FC<IProps> = (props) => {
  return (

    <GrRadialSelected
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconPO: React.FC<IProps> = (props) => {
  return (

    <BiWrench
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconProducts: React.FC<IProps> = (props) => {
  return (

    <FaBoxes
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconProgress: React.FC<IProps> = (props) => {
  return (
    <BsGraphUp
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention} />

  );
};



export const IconRefresh: React.FC<IProps> = (props) => {
  return (

    <FiRefreshCcw
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconReceivables: React.FC<IProps> = (props) => {
  return (

    <BsBootstrapReboot
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconReportDeposit: React.FC<IProps> = (props) => {
  return (

    <RiLuggageDepositFill
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconResidue: React.FC<IProps> = (props) => {
  return (

    <BsCurrencyExchange
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconMirror: React.FC<IProps> = (props) => {
  return (

    <BiCopy
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};


export const IconReturn: React.FC<IProps> = (props) => {
  return (
    <MdOutlineNavigateBefore
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.white}
    />

  );
};


export const IconReturn2: React.FC<IProps> = (props) => {
  return (
    <BsChevronDoubleLeft
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconSad: React.FC<IProps> = (props) => {
  return (

    <RiEmotionSadLine
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention} />

  );
};



export const IconScreenFull: React.FC<IProps> = (props) => {
  return (

    <AiOutlineFullscreen
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.black}
    />

  );
};



export const IconScreenNormalize: React.FC<IProps> = (props) => {
  return (

    <AiOutlineFullscreenExit
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.black}
    />

  );
};



export const IconSearch: React.FC<IProps> = (props) => {
  return (

    <FaSearch
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention} />

  );
};



export const IconSelect: React.FC<IProps> = (props) => {
  return (

    <AiOutlineSelect
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconSend: React.FC<IProps> = (props) => {
  return (

    <IoSend
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.buttonOk}
    />

  );
};



export const IconSendAttached: React.FC<IProps> = (props) => {
  return (

    <IoDocumentAttachSharp
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconSequenceNumber: React.FC<IProps> = (props) => {
  return (

    <TiSortNumerically
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconSetting: React.FC<IProps> = (props) => {
  return (

    <AiFillSetting
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconSystem: React.FC<IProps> = (props) => {
  return (

    <BsWindowSidebar
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.white}
    />

  );
};



export const IconStar: React.FC<IProps> = (props) => {
  return (

    <AiTwotoneStar
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconStatusNormal: React.FC<IProps> = (props) => {
  return (

    <><Tooltip title="Processo Normal" /><FaMinusCircle
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention} /></>

  );
};



export const IconStatusRegular: React.FC<IProps> = (props) => {
  return (
    <><Tooltip title="Regularização" /><BsFillLayersFill
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention} /></>

  );
};



export const IconStatusUrgent: React.FC<IProps> = (props) => {
  return (
    <><Tooltip title="Compra Urgente" /><BsExclamationTriangleFill
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention} /></>

  );
};



export const IconStock: React.FC<IProps> = (props) => {
  return (

    <FaBox
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconCNPJSupplier: React.FC<IProps> = (props) => {
  return (

    <BsBank2
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconTax: React.FC<IProps> = (props) => {
  return (

    <BsCalculatorFill
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconTransfer: React.FC<IProps> = (props) => {
  return (

    <BiTransfer
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconTrash: React.FC<IProps> = (props) => {
  return (

    <BsFillTrashFill
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention} />

  );
};



export const IconTruck: React.FC<IProps> = (props) => {
  return (

    <FaTruck
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconTwilight: React.FC<IProps> = (props) => {
  return (

    <MdWbTwilight
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconUser: React.FC<IProps> = (props) => {
  return (

    <ImUser
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconUserCheck: React.FC<IProps> = (props) => {
  return (

    <ImUserCheck
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention} />

  );
};



export const IconUserPlus: React.FC<IProps> = (props) => {
  return (

    <ImUserPlus
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};


export const IconUserMinus: React.FC<IProps> = (props) => {
  return (

    <FaMinusCircle
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconUsers: React.FC<IProps> = (props) => {
  return (

    <ImUsers
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconWarning: React.FC<IProps> = (props) => {
  return (

    <IoWarning
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};



export const IconWriteDown: React.FC<IProps> = (props) => {
  return (

    <MdInsertComment
      style={props.style}
      size={props.size || Metrics.iconSize}
      color={props.color || Colors?.attention}
    />

  );
};
