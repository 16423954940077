import React,
{
  useState,
  useEffect,
} from 'react';

import {
  Col,
  Modal,
} from 'react-bootstrap';

import {
  useHistory,
} from 'react-router';

import {
  Form,
  Formik,
} from 'formik';

import { SmeMotivoManutencao, SmeOrientacaoArray, SmeTipoManutencao } from '../../../../../../common/arrays/SmeSolicitacao';
import {
  Colors,
} from '../../../../../../common/constants';

import {
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';

import IButton from '../../../../../../components/Buttons/IButton';

import { IToast } from '../../../../../../components/IToast/Toast';
import {
  ModalBodyStyled,
  ModalContainerStyled,
  ModalFooterStyled,
} from '../../../../../../components/Modals/styled';

import DropdownInput from '../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../components/TextFields/Input';

import NameRoutes from '../../../../../../navigation/name';

import ApiWS from '../../../../../../services/api.service';
import SmeModalCopiaModel from './model';
import SmeCopiaModalValidationSchema from './validation';
import { AreaManutencaoFrotaStyle } from '../../SME_Solicitacao/SME_Nova_Copia/SME-solicitacao.styled';



interface IProps {
  show: boolean;
  dadosParaSM: any;
  onHide?: any;
}



const SMECopiaModal: React.FC<IProps> = (props) => {
  const history = useHistory();


  const [carregando, setCarregando] = useState(false);
  const [msg, setMsg] = useState('');

  const [selectCodUtilizacao, setSelectCodUtilizacao] = useState('18');
  const [selectCodOrientacao, setSelectCodOrientacao] = useState('00');
  const [statePrazo, setStatePrazo] = useState('0');
  const [stateCP_CLVL, setStateCP_CLVL] = useState('00');
  const [stateCP_TPMANUT, setSelectCP_TPMANUT] = useState('00');
  const [stateCP_MOTM, setSelectCP_MOTM] = useState('00');
  const [stateArmazem, setSelectArmazem] = useState('00');

  const [dropOrientacao, setDropOrientacao] = useState([]);

  const postDropOrientacao = async () => {
    try {
      const url = '/sm/nova_sm/consulta/orientacao';
      const response = await ApiWS().post(url);

      response.data.forEach((item) => {
        console.log(`${String(item.COD)?.trim()};${String(item.PRAZO)?.trim()}`);
        setDropOrientacao((dropOrientacao) => [...dropOrientacao, { key: item.ID, label: item.DESCR, value: `${String(item.COD)?.trim()};${String(item.PRAZO)?.trim()}` }]);
      });
    }
    catch (error) {
      console.log('postDropOrientacao', JSON.stringify(error.response, null, 2));
    }
  };

  const [dropUtilizacao, setDropUtilizacao] = useState([]);

  const postDropUtilizacao = async () => {
    try {
      const url = '/sm/nova_sm/consulta/utilizacao';
      const response = await ApiWS().post(url);

      response.data.forEach((item) => {
        setDropUtilizacao((dropUtilizacao) => [...dropUtilizacao, { key: item.X5_CHAVE, label: item.X5_DESCRI, value: item.X5_CHAVE }]);
      });
    }
    catch (error) {
      console.log('postDropUtilizacao', JSON.stringify(error.response, null, 2));
    }
  };


  async function copiaSME(values: any) {
    setCarregando(true);
    setMsg('');

    const url = '/sm/nova_sm/consulta/copiaSm';
    const payload = {
      'Num': values.input_num,
      'Filial': values.input_filial,
    };


    ApiWS()
      .post(url, payload)
      .then((resp) => {
        if (resp.status === 200) {
          const copiaSMEObjeto = {
            tipo: 'COPIA',
            solicitante: props.dadosParaSM.usu_nm_usuario,
            os: props.dadosParaSM.cco_vl_centro_custo,
            setor_operacao: props.dadosParaSM.cco_ds_centro_custo,
            filial: values.input_filial,
            funcaoSolicitante: props.dadosParaSM.fun_ds_funcao,
            sme: resp.data,
            utilizacao: selectCodUtilizacao,
            orientacao: selectCodOrientacao,
            prazo: statePrazo,
            placa: stateCP_CLVL,
            tipoManutencao: stateCP_TPMANUT,
            motivoManutencao: stateCP_MOTM,
            armazem: stateArmazem,
          };

          if (resp.data.length > 0) {
            history.push(`/${NameRoutes.SME_Nova}`, copiaSMEObjeto);
          }
          else {
            setMsg('SME ou Filial inxestente, verifique e tente outro número!');
          }
        }

        console.log(resp.data);
        if (resp.status === 203) {
          IToast({
            type: 'warning',
            message: resp.data,
          });
        }
      })
      .catch((error) => {
        console.error('copiaSME', JSON.stringify(error.response, null, 2));
      })
      .finally(() => {
        setCarregando(false);
      });
  };

  const [dropArmazem, setDropArmazem] = useState([]);

  const postDropArmazem = async () => {
    setCarregando(true);
    try {
      const url = '/sm/nova_sm/consulta/armazem';
      const response = await ApiWS().post(url);

      response.data.forEach((item, index) => {
        setDropArmazem((dropArm) => [...dropArm, { key: index, label: item.TELA, value: item.COD }]);
      });
    }
    catch (error) {
      console.log('postDropArmazem', JSON.stringify(error.response, null, 2));
    }
    finally {
      setCarregando(false);
    }
  };

  useEffect(() => {
    postDropOrientacao();
    postDropUtilizacao();
    postDropArmazem();
  }, []);



  return (

    <Modal
      {...props}
      show={props.show}
      backdrop={'static'}
      size={'lg'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {'Cópia SME'.toUpperCase()}
        </TitleGroup>



        <Formik
          enableReinitialize
          validateOnMount
          validationSchema={SmeCopiaModalValidationSchema}
          initialValues={SmeModalCopiaModel}
          onSubmit={(values) => {
            copiaSME(values);
          }}>
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
          }) => (

            <Form onSubmit={handleSubmit}>

              <ModalBodyStyled>
                <TextInput
                  editable={!carregando}
                  type={'text'}
                  name={'input_filial'}
                  label={'Filial'}
                  placeholder={'Numero Filial'}
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue('input_filial', e.target.value.replace(/\D/, ''));
                  }}
                  onBlur={handleBlur}
                />


                <DropdownInput
                  editable={!carregando}
                  name={'input_armazem'}
                  label={'Armazém'}
                  placeholder={'Selecione ...'}
                  dropArray={dropArmazem}
                  onChange={(e: any) => {
                    handleChange(e);
                    setSelectArmazem(e.target.value);
                    setFieldValue('input_armazem', e.target.value);
                  }}
                  onBlur={handleBlur}
                />


                <TextInput
                  editable={!carregando}
                  type={'text'}
                  name={'input_num'}
                  label={'SME'}
                  placeholder={'Numero SME'}
                  min={0}
                  max={6}
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue('input_num', e.target.value.replace(/\D/, ''));
                  }}
                  onBlur={handleBlur}
                />


                <DropdownInput
                  editable={!carregando}
                  name={'input_utilizacao'}
                  label={'Utilização'}
                  placeholder={'Selecione ...'}
                  dropArray={dropUtilizacao}
                  onChange={(e: any) => {
                    handleChange(e);
                    setSelectCodUtilizacao(e.target.value);
                    setFieldValue('input_utilizacao', e.target.value);
                  }}
                  onBlur={handleBlur}
                />


                <DropdownInput
                  editable={!carregando}
                  name={'input_orientacao'}
                  label={'Orientação'}
                  placeholder={'Selecione ...'}
                  dropArray={dropOrientacao}
                  onChange={(e: any) => {
                    handleChange(e);
                    // setInputLimite(handleOrientacaoPrazo(e.target.value));
                    setSelectCodOrientacao(e.target.value.substring(0, e.target.value.indexOf(';')));
                    setFieldValue('input_orientacao', e.target.value.substring(0, e.target.value.indexOf(';')));
                    setStatePrazo(e.target.value.substring(e.target.value.indexOf(';') + 1, e.target.value.length));
                  }}
                  onBlur={handleBlur}
                />


                {selectCodOrientacao === '15' && (
                  <AreaManutencaoFrotaStyle>

                    <Col xs={6}>
                      <TextInput
                        editable={!carregando}
                        name={'CP_CLVL'}
                        type={'text'}
                        label={'Placa'}
                        placeholder={'Placa'}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue('CP_CLVL', e.target.value);
                          setStateCP_CLVL(e.target.value);
                        }}
                        onBlur={handleBlur}
                      />
                    </Col>


                    <Col xs={6}>
                      <DropdownInput
                        editable={!carregando}
                        name={'CP_TPMANUT'}
                        label={'Tipo Manutenção'}
                        placeholder={'Selecione ...'}
                        dropArray={SmeTipoManutencao}
                        onChange={(e) => {
                          handleChange(e);
                          setSelectCP_TPMANUT(e.target.value);

                          setFieldValue('CP_TPMANUT', e.target.value);
                        }}
                        onBlur={handleBlur}
                      />
                    </Col>


                    {stateCP_TPMANUT === '01' && (
                      <Col xs={12}>
                        <DropdownInput
                          editable={!carregando}
                          name={'CP_MOTM'}
                          label={'Motivo Manutenção'}
                          placeholder={'Selecione ...'}
                          dropArray={SmeMotivoManutencao}
                          onChange={(e) => {
                            handleChange(e);
                            setSelectCP_MOTM(e.target.value);

                            setFieldValue('CP_MOTM', e.target.value);
                          }}
                          onBlur={handleBlur}
                        />
                      </Col>
                    )}

                  </AreaManutencaoFrotaStyle>
                )}



                {msg !== '' && (
                  <p className="text-danger">
                    {msg}
                  </p>
                )}
              </ModalBodyStyled>



              <ModalFooterStyled>
                <IButton
                  backgroundColor={Colors?.buttonOk}
                  text={'Copiar'}
                  type={'submit'}
                />


                <IButton
                  backgroundColor={Colors?.buttonCancel}
                  text={'Fechar'}
                  onClick={props.onHide}
                />
              </ModalFooterStyled>

            </Form>

          )}
        </Formik>

      </ModalContainerStyled>

    </Modal>

  );
};



export default SMECopiaModal;
