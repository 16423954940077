import React,
{
  useState,
  useEffect,
} from 'react';

import { Col, OverlayTrigger } from 'react-bootstrap';
import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import {
  useSelector,
} from 'react-redux';

import {
  toast,
} from 'react-toastify';

import { Formik } from 'formik';
import {
  PcbAprovarArray,
} from '../../../../../common/arrays';

import { YesNoArray2 } from '../../../../../common/arrays/YesNo';
import {
  Colors,
  Images,
  Metrics,
} from '../../../../../common/constants';
import { TextTooltip } from '../../../../../common/constants/TextTooltips';

import {
  ContentStyled,
  Content,
  Screen,
  TitleGroup,
  ContainerView,
  TableTdButtonNextCss,
  TableRotateNextCss,
  TableCheckBoxNextCss,
  FilterContainerForm,
  SectionMargin,
  RowButtonFiltersView,
  TableToltip,
} from '../../../../../common/styles/styled.layout';

import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';
import ButtonTableCheck from '../../../../../components/Buttons/ButtonTableCheck';
import IButton from '../../../../../components/Buttons/IButton';
import SubtitleComponent from '../../../../../components/Contents/Subtitles';

import EmptyContent from '../../../../../components/Empty';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';

import {
  IconFlag,
  IconAttachment,
  IconFolder,
  IconHistory,
  IconMoreOptions,
  IconWriteDown,
  IconDocuments,
} from '../../../../../components/Icons';

import LoadingsComponent from '../../../../../components/Loadings';
import TableCuston from '../../../../../components/Table';
import DropdownInput from '../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../components/TextFields/Input';
import NameRoutes from '../../../../../navigation/name';

import {
  PBC_NavRoutes,
} from '../../../../../navigation/navs/Supplies';

import ApiWS, { defaultBaseURL, ambiente } from '../../../../../services/api.service';

import {
  RootState,
} from '../../../../../store';

import {
  convertCurrencyBRL,
} from '../../../../../utils/CurrencyUtils';

import {
  returnErrorObject,
} from '../../../../../utils/ErrorUtils';

import AnexosPcbModal from '../Modals/AnexosPcbModal';
import AnotacaoModal from '../Modals/AnotacaoPcbModal';
import CotacaoPcbModal from '../Modals/CotacaoPcbModal';
import ProdutoDadosComplementaresModal from '../Modals/DadosComplementaresModal';
import HistoricoAprovaçãoPedidoModal from '../Modals/HistoricoAprovaçãoPedidoModal';
import HistoricoFornecedorModal from '../Modals/HistoricoFornecedorModal';
import ItensContratoModal from '../Modals/ItensContratoModal';
import ItensPedidoModal from '../Modals/ItensPedidoModal';
import JustificativaReprovarModal from '../Modals/JustificativaReprovar';

import PcbAprovacaoModel from './PBC-aprovacao.model';
import {
  FlagsPcbView,
  FieldFilterView,
} from './PBC-aprovacao.styled';
import PcbAprovacaoValidationSchema from './PBC-aprovacao.validation';
import { IToast } from '../../../../../components/IToast/Toast';
import { FAKEListRequest } from '../../../../../common/FakeData/Supplier/PBC/Aprovacao';


const PBC_AprovacaoScreen: React.FC = () => {
  // ----------- AREA STATES ---------------------------
  const pasta = localStorage.getItem('@intranet/pasta');

  const user = useSelector((state: RootState) => state.user.data);


  const [carregando, setCarregando] = useState(false);

  const emptyObject = {
    image: Images.empty,
    title: 'Sem pedidos aqui!',
    description: 'Não há nenhum pedido para você',
  };

  const [valuesFetch, setValuesFetch] = useState({ CC: '', NUM: '', ANEXO: 'TODOS' });

  const [valueSupplier, setValueSupplier] = useState(null);
  const [showModalSupplier, setShowModalSupplier] = useState(false);

  const [modalAnexo, setModalAnexo] = useState(false);
  const [valueAnexo, setValueAnexo] = useState(null);

  const [valueHistory, setValueHistory] = useState(null);
  const [modalHistory, setModalHistory] = useState(false);

  const [valueItems, setValueItems] = useState(null);
  const [itemsModalShow, setItemsModalShow] = useState(false);

  const [valueItensCtr] = useState(null);
  const [itensCtrModalShow, setItensCtrModalShow] = useState(false);

  const [valueQuotation, setValueQuaotation] = useState(null);
  const [showQuaotationModal, setShowQuotationModal] = useState(false);

  const [valueComplementaryData, setValueComplemetaryData] = useState(null);
  const [complementaryDataModalShow, setComplementaryDataModalShow] = useState(false);

  const [justificationModalShow, setJustificationModalShow] = useState(false);

  const [listRequest, setListRequest] = useState([]);
  const [listToApprove, setListToApprove] = useState([]);

  const [writeDownModalShow, setWriteDownModalShow] = useState(false);
  const [writeDownValues, setWriteDownValues] = useState([]);
  const [typeWriteDown, setTypeWriteDown] = useState(null);


  const [totalResults, setTotalResults] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [valuesPayload, setValuesPayload] = useState(null);


  // ----------- AREA FUNCTION'S FETH DATA ---------------------------

  async function postFetchAll(values, page_iteration: any) {
    setCarregando(true);
    setListRequest([]);
    setValuesPayload(values);

    values = {
      ...values,
      'PAGINA': page_iteration,
      'TAMANHO': Metrics.size_PBCApprovall,
    };

    ApiWS()
      .post('/suprimento/aprovacao/consulta', values)
      .then((resp) => {
        // if (ambiente !== 'DEV') {
        // --> PRD
        setListRequest(resp.data[0]);
        setValuesFetch(values);
        setListToApprove([]);
        setTotalResults(resp.data[1][0].TOTAL);
        setTotalPage(Math.ceil(resp.data[1][0].TOTAL / 10));
        // }
        // else {
        //   // --> FAKE
        //   setListRequest(FAKEListRequest);
        //   setValuesFetch(values);
        //   setListToApprove([]);
        //   setTotalResults(10);
        //   setTotalPage(1);
        // }
      })
      .catch((error) => {
        console.error('fetchAll', JSON.stringify(error.response, null, 2));
      })
      .finally(() => {
        setCarregando(false);
      });
  };

  async function handlePagination(type: number) {
    let page_var = page;


    if (type === 0 && page_var > 1) {
      page_var = page_var - 1;
    }
    if (type === 1 && page_var < totalPage) {
      page_var = page_var + 1;
    }

    postFetchAll(valuesPayload, page_var);
    setPage(page_var);
  };


  function postPrinter(url: any) {
    setCarregando(true);


    ApiWS()
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          window.open(`${defaultBaseURL}/${pasta}/temp/${res.data}`);
        }
      })
      .catch((err) => {
        console.error('postPrinter:::', JSON.stringify(err));

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setCarregando(false);
      });
  };



  function getPrinterMap(values: any) {
    setCarregando(true);

    const url = `/suprimentos/consulta/pedido/mapa/${values.FILIAL}/${values.NUM}`;

    ApiWS()
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          window.open(`${defaultBaseURL}/${pasta}/temp/${res.data}`);
        }
      })
      .catch((err) => {
        console.error('postPrinter:::', JSON.stringify(err));

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setCarregando(false);
      });
  };


  // ----------- AREA FUNCTION'S RENDER BUTTONS TABLE ---------------

  function renderFlagOrient(cell: any, row: any) {
    return (
      PcbAprovarArray.map((orient) => (
        orient.key === row.COD_ORIGPC
          ? <IconFlag color={orient.color} size={16} />
          : ''
      ))
    );
  }

  function renderCheckboxAddItem(cell: any, row: any) {
    return (
      <ButtonTableCheck
        tooltip={`
          ${row?.TIPO}: ${row?.NUM} |
          FILIAL: ${row?.FILIAL} |
          CC: ${row?.CC1}
        `}
        onChange={(chk) => {
          const pcBm = {
            'USER': user.id,
            'FILIAL': row.FILIAL,
            'NUM': row.NUM,
            'TIPO': row.TIPO,
            'NIVEL': row.NIVEL,
            'VALOR': row.VALOR,
          };
          chk.target.checked ? addPCBMCart(pcBm) : removePCBMCart(pcBm);
        }}
      />
    );
  };


  function renderBtnModalSupplier(cell: any, row: any) {
    return (
      <TableTdButtonNextCss
        onClick={() => {
          setValueSupplier({
            FILIAL: row.FILIAL,
            FORNEC: row.CODFORNEC,
            LOJA: row.LOJA,
            CC: row.CC1,
          });
          setShowModalSupplier(true);
        }}>
        {cell}
      </TableTdButtonNextCss>
    );
  };


  function returnSwitchBtnItems(cell: any, row: any) {
    switch (row.TIPO) {
      case 'CT':
        postPrinter(`/suprimentos/contrato/impressao/${row.FILIAL}/${row.CONTRATO}/${row.REVISAO}/${row.PLANILHA}`);
        break;
      case 'CP':
        postPrinter(`/suprimentos/contrato/impressao/${row.FILIAL}/${row.CONTRATO}/${row.REVISAO}/${row.PLANILHA}`);
        break;
      case 'BM':
        postPrinter(`/suprimentos/consulta/pedido/medicao/impressao/${row.FILIAL}/${row.NUM}/${row.TIPO}`);
        break;
      case 'MD':
        postPrinter(`/suprimentos/consulta/pedido/medicao/impressao/${row.FILIAL}/${row.NUM}/${row.TIPO}`);
        break;
      case 'AE':
        postPrinter(`/suprimentos/consulta/pedido/medicao/impressao/${row.FILIAL}/${row.NUM}/${row.TIPO}`);
        break;

      default:
        getPrinterMap(row);
        break;
    }
  };


  function renderBtnModalMapa(cell: any, row: any) {
    return (
      <ButtonTableAction
        tooltip={TextTooltip.MAP}
        onClick={() => {
          returnSwitchBtnItems(cell, row);
        }}
        icon={
          <IconFolder
            color={Colors?.black}
            size={16}
          />
        }
      />
    );
  };


  function renderBtnModalItems(cell: any, row: any) {
    return (
      <ButtonTableAction
        tooltip={row.TIPO === 'PC' ? TextTooltip.ITEMS : TextTooltip.NO_PC}
        onClick={() => {
          if (row.TIPO === 'PC') {
            setValueItems({
              'TIPO': row.TIPO,
              'FILIAL': row.FILIAL,
              'PEDIDO': row.NUM,
            });

            setItemsModalShow(true);
          }
        }}
        icon={
          <IconDocuments
            color={row.TIPO === 'PC' ? Colors?.black : Colors?.gray}
            size={16}
          />
        }
      />
    );
  };


  function renderBtnModalHistory(cell: any, row: any) {
    return (
      <ButtonTableAction
        tooltip={TextTooltip.HISTORIC}
        onClick={() => {
          setValueHistory({
            'FILIAL': row.FILIAL,
            'CC': row.CC1,
            'NUM': row.NUM,
            'TIPO': row.TIPO,
          });
          setModalHistory(true);
        }}
        icon={
          <IconHistory
            color={Colors?.black}
            size={16}
          />
        }
      />
    );
  };


  function renderBtnModalAttached(cell: any, row: any) {
    return (
      <ButtonTableAction
        tooltip={
          row.ANEXO === 'S'
            ? TextTooltip.ATTACHMENT
            : TextTooltip.LESS_ATTACHMENT
        }
        onClick={() => {
          if (row.ANEXO === 'S') {
            setValueAnexo({
              'TIPO': row.TIPO,
              'NUM': row.NUM,
              'FILIAL': row.FILIAL,
            });
            setModalAnexo(true);
          }
        }}
        icon={
          <IconAttachment
            color={
              row.ANEXO === 'S'
                ? Colors?.black
                : Colors?.gray
            }
            size={16}
          />
        }
      />
    );
  };


  function renderBtnModalComplementary(cell: any, row: any) {
    return (
      <ButtonTableAction
        tooltip={TextTooltip.COMPLEMENTARY_DATA}
        onClick={() => {
          setValueComplemetaryData({
            'CONDPAG': row.CONDPAG,
            'FILIALENT': row.FILIALENT,
            'ENDENTR': row.ENDENTR,
            'DTENTR': row.DTENTR,
            'FRETE': row.FRETE,
            'OP': row.OP,
            'OBS': row.OBS,
            'JUST': row.JUST,
            'IMP': row.IMP,
            'VALORI': row.VALORI,
          });
          setComplementaryDataModalShow(true);
        }}
        icon={
          <IconMoreOptions
            color={Colors?.black}
            size={16}
          />
        }
      />
    );
  };


  function renderBtnModalWrite(cell: any, row: any) {
    return (
      <ButtonTableAction
        tooltip={TextTooltip.WRITE_DOWN}
        onClick={() => {
          setWriteDownValues([{
            'FILIAL': row.FILIAL,
            'NUM': row.NUM,
            'TIPO': row.TIPO,
          }]);
          setTypeWriteDown('read');
          setWriteDownModalShow(true);
        }}
        icon={
          <IconWriteDown
            color={Colors?.black}
            size={16}
          />
        }
      />
    );
  };



  // ----------- AREA FUNCTION'S ACTION FORM ----------------------------

  const addPCBMCart = (pcBm: any) => {
    setListToApprove((listParaAprovar) => [...listParaAprovar, pcBm]);
  };


  const removePCBMCart = (pcBm: any) => {
    const index = listToApprove.findIndex((x) => x.NUM === pcBm.NUM);
    const newList = [...listToApprove];
    newList.splice(index, 1);
    setListToApprove(newList);
  };

  function postToApprove(listPcBm: any) {
    setCarregando(true);

    ApiWS()
      .post('/suprimento/aprovacao/aprovar', listPcBm)
      .then((resp) => {
        if (resp.status === 200) {
          toast.success('PC / BM Aprovado com sucesso!', {
            position: 'bottom-center',
            autoClose: Metrics.messageAutoClose,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.error('aprovar', JSON.stringify(error.response, null, 2));

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setCarregando(false);
        setListToApprove([]);
        postFetchAll(valuesFetch, 1);
      });
  };


  function postToReprobate(listPcBm: any) {
    setCarregando(true);

    ApiWS()
      .post('/suprimento/aprovacao/reprovar', listPcBm)
      .then((resp) => {
        if (resp.status === 200) {
          toast.success('PC / BM Reprovado com sucesso!', {
            position: 'bottom-center',
            autoClose: Metrics.messageAutoClose,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.error('reprovar', JSON.stringify(error.response, null, 2));

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setCarregando(false);
        setListToApprove([]);
        postFetchAll(valuesFetch, 1);
      });
  };


  function handleReprovar(justificativa: string) {
    listToApprove.length > 0 &&
      listToApprove.forEach((pcBm) => {
        pcBm.JUST = justificativa;
      });

    postToReprobate(listToApprove);
  };



  // ----------- AREA FUNTION'S RENDER MODAL ---------------------------

  function renderModalHistory() {
    return (
      <HistoricoAprovaçãoPedidoModal
        show={modalHistory}
        value={valueHistory}
        onHide={() => {
          setModalHistory(false);
          setTimeout(() => {
            setValueHistory(null);
          }, 300);
        }}
      />
    );
  };


  function renderModalComplementaryData() {
    return (
      <ProdutoDadosComplementaresModal
        show={complementaryDataModalShow}
        dadosComp={valueComplementaryData}
        onHide={() => {
          setComplementaryDataModalShow(false);
          setTimeout(() => {
            setValueComplemetaryData(null);
          }, 300);
        }}
      />
    );
  };


  function renderModalJustificationModalShow() {
    return (
      <JustificativaReprovarModal
        show={justificationModalShow}
        justificativa={(just: string) => {
          handleReprovar(just);
        }}
        onHide={() => {
          setJustificationModalShow(false);
          setTimeout(() => {
            setValueComplemetaryData(null);
          }, 300);
        }}
      />
    );
  };


  function renderModalAttached() {
    return (
      <AnexosPcbModal
        show={modalAnexo}
        values={valueAnexo}
        onHide={() => {
          setModalAnexo(false);
          setTimeout(() => {
            setValueAnexo(null);
          }, 300);
        }}
      />
    );
  };


  function renderQuotation() {
    return (
      <CotacaoPcbModal
        show={showQuaotationModal}
        values={valueQuotation}
        onHide={() => {
          setShowQuotationModal(false);
          setTimeout(() => {
            setValueQuaotation(null);
          }, 300);
        }}
      />
    );
  };


  function renderModalItemsCtr() {
    return (
      <ItensContratoModal
        show={itensCtrModalShow}
        values={valueItensCtr}
        onHide={() => {
          setItensCtrModalShow(false);
          setTimeout(() => {
            setValueItems(null);
          }, 300);
        }}
      />
    );
  };


  function renderModalItemsRequest() {
    return (
      <ItensPedidoModal
        show={itemsModalShow}
        values={valueItems}
        onHide={() => {
          setItemsModalShow(false);
          setTimeout(() => {
            setValueItems(null);
          }, 300);
        }}
        cotacaoClick={(cotacao) => {
          setShowQuotationModal(true);
          setValueQuaotation(cotacao);
        }}
      />
    );
  };


  function renderModalSupplier() {
    return (
      <HistoricoFornecedorModal
        show={showModalSupplier}
        supplier={valueSupplier}
        onHide={() => {
          setShowModalSupplier(false);
          setTimeout(() => {
            setValueSupplier(null);
          }, 300);
        }}
      />
    );
  };


  function renderModalWriteDownShow() {
    return (
      <AnotacaoModal
        show={writeDownModalShow}
        type={typeWriteDown}
        pedidos={writeDownValues}
        onHide={() => {
          setWriteDownModalShow(false);
          setTimeout(() => {
            setWriteDownValues(null);
          }, 300);
        }}
      />
    );
  };



  useEffect(() => {
    postFetchAll(valuesFetch, 1);
  }, []);



  const columns = [
    {
      dataField: '',
      text: '',
      headerStyle: () => {
        return TableCheckBoxNextCss;
      },
      formatter: (cell, row) => {
        return renderCheckboxAddItem(cell, row);
      },
    },
    {
      dataField: '',
      text: 'Orient.',
      headerStyle: () => {
        return { width: '3%' };
      },
      formatter: (cell, row) => {
        return renderFlagOrient(cell, row);
      },
    },
    {
      dataField: 'TIPO',
      text: 'Tipo',
      sort: true,
      headerStyle: () => {
        return { width: '3%' };
      },
    },
    {
      dataField: 'NUM',
      text: 'PC/BM',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'CONTRATO',
      text: 'N Doc',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'EMISSAO',
      text: 'Data',
      sort: true,
      headerStyle: () => {
        return { width: '5.5%' };
      },
    },
    {
      dataField: 'COMPRADOR',
      text: 'Comprador/Analista',
      sort: true,
    },
    {
      dataField: 'FORNECEDOR',
      text: 'Fornecedor',
      sort: true,
      formatter: (cell, row) => {
        return renderBtnModalSupplier(cell, row);
      },
    },
    {
      dataField: 'VALOR',
      text: 'Valor',
      sort: true,
      headerStyle: () => {
        return { width: '5.5%' };
      },
      formatter: (cell) => {
        return convertCurrencyBRL(cell);
      },
    },
    {
      dataField: 'CC1',
      text: 'CC',
      sort: true,
      headerStyle: () => {
        return { width: '6%' };
      },
      formatter: (cell, row) => {
        return (
          <OverlayTrigger
            key={'right'}
            placement={'right'}
            trigger={['hover', 'hover']}
            overlay={
              <TableToltip>
                {row.CCDESCR}
              </TableToltip>
            }>
            <span>{row.CC1}</span>
          </OverlayTrigger>
        );
      },
    },
    {
      dataField: 'REVISAO',
      text: 'Revisão',
      sort: true,
      headerStyle: () => {
        return { width: '5%' };
      },
    },
    {
      dataField: '',
      text: 'Itens',
      headerClasses: 'text-wrap-normal',
      headerStyle: () => {
        return TableRotateNextCss;
      },
      formatter: (cell, row) => {
        return renderBtnModalItems(cell, row);
      },
    },
    {
      dataField: '',
      text: 'Mapa',
      headerClasses: 'text-wrap-normal',
      headerStyle: () => {
        return TableRotateNextCss;
      },
      formatter: (cell, row) => {
        return renderBtnModalMapa(cell, row);
      },
    },
    {
      dataField: '',
      text: 'Hist.',
      headerClasses: 'text-wrap-normal',
      headerStyle: () => {
        return TableRotateNextCss;
      },
      formatter: (cell, row) => {
        return renderBtnModalHistory(cell, row);
      },
    },
    {
      dataField: '',
      text: 'Anex.',
      headerClasses: 'text-wrap-normal',
      sort: true,
      headerStyle: () => {
        return TableRotateNextCss;
      },
      formatter: (cell, row) => {
        return renderBtnModalAttached(cell, row);
      },
    },
    {
      dataField: '',
      text: 'Comp.',
      headerClasses: 'text-wrap-normal',
      headerStyle: () => {
        return TableRotateNextCss;
      },
      formatter: (cell, row) => {
        return renderBtnModalComplementary(cell, row);
      },
    },
    {
      dataField: '',
      text: 'Anot.',
      headerClasses: 'text-wrap-normal',
      headerStyle: () => {
        return TableRotateNextCss;
      },
      formatter: (cell, row) => {
        return renderBtnModalWrite(cell, row);
      },
    },
  ];


  const options = {
    custom: true,
    totalSize: listRequest.length,
  };


  // ----------- AREA RETURN JSX ---------------------------

  return (

    <Screen>

      <NavHeader>
        <NavUser backHome />


        <NavComp
          navKey={1}
          navArray={PBC_NavRoutes}
          title={'Suprimentos'}
        />
      </NavHeader>



      <Content>
        <ContentStyled fluid>

          <ContainerView>

            <SectionMargin>

              <TitleGroup>
                {'Aprovação'.toUpperCase()}
              </TitleGroup>


              {carregando && (
                <LoadingsComponent
                  type={'SCREEN'}
                  color={Colors?.tertiary}
                />
              )}

              <SectionMargin>
                <Formik
                  enableReinitialize
                  validateOnMount
                  validationSchema={PcbAprovacaoValidationSchema}
                  initialValues={PcbAprovacaoModel}
                  onSubmit={(values) => {
                    postFetchAll(values, 1);
                  }}>
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                  }) => (

                    <FilterContainerForm auto onSubmit={handleSubmit}>

                      <FieldFilterView>

                        <Col>
                          <TextInput
                            disabled={carregando}
                            type={'text'}
                            name={'CC'}
                            label={'CC'}
                            placeholder={'CC'}
                            min={0}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.CC || ''}
                          />
                        </Col>

                        <Col>
                          <TextInput
                            disabled={carregando}
                            type={'text'}
                            name={'NUM'}
                            label={'PC/BM/CP/AE'}
                            placeholder={'Pedido'}
                            min={0}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.NUM || ''}
                          />
                        </Col>

                        <Col>
                          <DropdownInput
                            disabled={carregando}
                            name={'ANEXO'}
                            label={'Possui Anexo?'}
                            defaultValue={'Todos'.toLocaleUpperCase()}
                            dropArray={[
                              {
                                key: 'All',
                                label: 'Todos',
                                value: 'Todos'.toLocaleUpperCase(),
                              },
                              ...YesNoArray2,
                            ]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.ANEXO || ''}
                          />
                        </Col>

                      </FieldFilterView>


                      <FlagsPcbView>
                        <SubtitleComponent
                          array={PcbAprovarArray}
                          showFlag={true}
                        />
                      </FlagsPcbView>


                      <RowButtonFiltersView>

                        <IButton
                          disabled={(carregando || listToApprove.length <= 0)}
                          backgroundColor={Colors?.buttonOk}
                          text={'Aprovar'}
                          onClick={() => {
                            postToApprove(listToApprove);
                          }}
                        />


                        <IButton
                          disabled={(carregando || listToApprove.length <= 0)}
                          backgroundColor={Colors?.buttonCancel}
                          text={'Reprovar'}
                          onClick={() => {
                            setJustificationModalShow(true);
                          }}
                        />


                        <IButton
                          disabled={(carregando || listToApprove.length <= 0)}
                          backgroundColor={Colors?.blueDark}
                          text={'Anotações'}
                          onClick={() => {
                            setWriteDownValues(listToApprove);
                            setTypeWriteDown('write');
                            setTimeout(() => {
                              setWriteDownModalShow(true);
                            }, 300);
                          }}
                        />


                        <IButton
                          disabled={carregando}
                          backgroundColor={Colors?.grayDark}
                          text={'Filtrar'}
                          type={'submit'}
                        />

                      </RowButtonFiltersView>

                    </FilterContainerForm>

                  )}
                </Formik>
              </SectionMargin>

              <ContainerView>

                {!carregando && listRequest.length === 0 && (
                  <EmptyContent
                    image={emptyObject.image}
                    title={emptyObject.title}
                    description={emptyObject.description}
                  />
                )}


                {(!carregando && listRequest.length > 0) && (

                  <PaginationProvider
                    pagination={paginationFactory(options)}>
                    {({
                      paginationProps,
                      paginationTableProps,
                    }) => (
                      <TableCuston
                        noQuantity={totalPage === 1}
                        data={listRequest}
                        columns={columns}
                        paginationProps={paginationProps}
                        paginationTableProps={paginationTableProps}
                        actionPrevious={() => handlePagination(0)}
                        actionNext={() => handlePagination(1)}
                        currentPage={page}
                        totalPage={totalPage}
                        totalResults={totalResults}
                      />
                    )}
                  </PaginationProvider>

                )}

              </ContainerView>

            </SectionMargin>

          </ContainerView>

        </ContentStyled>
      </Content>



      {
        valueSupplier &&
        renderModalSupplier()
      }



      {
        valueItems &&
        renderModalItemsRequest()
      }


      {
        valueItensCtr &&
        renderModalItemsCtr()
      }



      {
        valueQuotation &&
        renderQuotation()
      }



      {
        valueAnexo && modalAnexo &&
        renderModalAttached()
      }



      {
        valueHistory &&
        renderModalHistory()
      }



      {
        valueComplementaryData &&
        renderModalComplementaryData()
      }



      {
        justificationModalShow &&
        renderModalJustificationModalShow()
      }



      {
        writeDownModalShow && writeDownValues &&
        renderModalWriteDownShow()
      }

    </Screen >

  );
};

export default PBC_AprovacaoScreen;
